import {
  UlyDisablementReason,
  UlyServiceLocation,
  UlyPickupLocation,
  UlyDropoffLocation,
  UlyExchangeLocation,
  UlyServices,
  UlyRide,
  UlyPaymentInfo,
  ULYCustomerContact,
  UlyServiceCodes,
  PartialVehicleWithStringYear,
  UlyLocationTypeCodes,
  ULY_PARTNER_CODES,
  CaseServiceCall,
} from 'types/urgently'

import { Branch, Maybe } from 'types/global'
import tzLookup from 'tz-lookup'
import { SoftService, Tow, TowExchange, Lockout, Tires, Jumpstart, Winch } from 'types/callstore'
import { EventTypes } from 'types/events'
import { isLocation, isNumeric, isParticipant, isBranch } from 'utils/typeChecks'
import { VehicleConditions } from 'components/VehicleCondition'
import { BreakdownOptions } from 'forms/CallForm/BreakdownDetails/BreakdownRadio'
import { LocationPayload } from 'types/location'
import { isNil } from 'lodash-es'

export const ulyServiceCodeLabels = {
  [UlyServiceCodes.Storage]: 'Storage',
  [UlyServiceCodes.Towing]: 'Towing',
  [UlyServiceCodes['Auto Lockout']]: 'Auto Lockout',
  [UlyServiceCodes['Jump Start']]: 'Jump Start',
  [UlyServiceCodes.Fuel]: 'Fuel',
  [UlyServiceCodes['Flat Tire']]: 'Flat Tire',
  [UlyServiceCodes.Emergency]: 'Emergency',
  [UlyServiceCodes.Winch]: 'Winch',
  [UlyServiceCodes['Key Replacement']]: 'Key Replacement',
  [UlyServiceCodes['Dealer Tire Service']]: 'Dealer Tire Service',
  [UlyServiceCodes['Motorcycle Tow']]: 'Motorcycle Tow',
  [UlyServiceCodes['Home Lockout']]: 'Home Lockout',
  [UlyServiceCodes['Tire Replacement']]: 'Tire Replacement',
  [UlyServiceCodes['Medium/Heavy Duty RSA']]: 'Medium/Heavy Duty RSA',
  [UlyServiceCodes['Medium Duty Tow']]: 'Medium Duty Tow',
  [UlyServiceCodes['Heavy Duty Tow']]: 'Heavy Duty Tow',
  [UlyServiceCodes['Secondary Tow']]: 'Secondary Tow',
  [UlyServiceCodes['Accident Tow']]: 'Accident Tow',
  [UlyServiceCodes['Short Term Mobility']]: 'Short Term Mobility',
  [UlyServiceCodes['Repair Shop']]: 'Repair Shop',
  [UlyServiceCodes['Appliance Repair']]: 'Appliance Repair',
  [UlyServiceCodes['HVAC']]: 'HVAC',
  [UlyServiceCodes['Plumbing']]: 'Plumbing',
  [UlyServiceCodes['Electrical']]: 'Electrical',
  [UlyServiceCodes['Mobile Mechanic']]: 'Mobile Mechanic',
  [UlyServiceCodes['Tire Delivery']]: 'Tire Delivery',
  [UlyServiceCodes['Battery Replacement']]: 'Battery Replacement',
  [UlyServiceCodes.Transport]: 'Transport',
  [UlyServiceCodes['Tow Exchange']]: 'Tow Exchange',
}

const REQUIRED_PHOTOS_COMMENT = 'PHOTOS REQUIRED'
const DEFAULT_CUSTOM_VIN_VEHICLE = {
  year: new Date().getFullYear(), // Set vehicle year to current year
  make: 'N/A',
  model: 'N/A',
  licensePlate: 'N/A',
  licensePlateState: 'N/A',
  vin: '',
  color: '#000000',
}

export const createSoftService = (
  standard: CaseServiceCall,
  softService: SoftService & { scheduledAt?: string },
  serviceCode: UlyServiceCodes,
  disablementReason: UlyDisablementReason,
): CaseServiceCall => {
  // EHI Ticket vehicle year is INT -- ULY is expecting String
  const formattedVehicle = { ...softService.vehicle, year: softService.vehicle?.year?.toString() }

  DEFAULT_CUSTOM_VIN_VEHICLE.vin = softService.customVin ?? ''
  const customFormattedVehicle = { ...DEFAULT_CUSTOM_VIN_VEHICLE, year: DEFAULT_CUSTOM_VIN_VEHICLE.year.toString() }

  let request = standard
  request = softService.customVin
    ? addVehicle(request, customFormattedVehicle)
    : (request = addVehicle(request, formattedVehicle))

  let serviceLocation

  const vehicleLocation = softService.serviceLocations.vehicleLocation
  if (vehicleLocation) serviceLocation = addServiceLocation(vehicleLocation) as UlyServiceLocation

  const { breakdownOption } = softService
  const breakDownLocation =
    breakdownOption === BreakdownOptions.ROADWAYINTERSTATE || breakdownOption === BreakdownOptions.PARKINGGARAGE
      ? breakdownOption + ': '
      : ''

  const paymentInfo = addPaymentInfo(request)
  const requestPayload = { code: '', name: '', serviceLocation, scheduledAt: '', notes: '', paymentInfo, disablementReason }

  requestPayload.code = serviceCode as unknown as string
  requestPayload.name = ulyServiceCodeLabels[serviceCode]
  requestPayload.scheduledAt = softService.scheduledAt ?? ''
  requestPayload.notes = breakDownLocation + softService.notes

  request = addService(request, requestPayload)
  return request
}

export const createTowExchangeService = (
  standard: CaseServiceCall,
  towExchange: TowExchange & { scheduledAt?: string; customVin?: Maybe<string> },
  serviceCode: UlyServiceCodes,
  disablementReason: UlyDisablementReason,
): CaseServiceCall => {
  // EHI Ticket vehicle year is INT -- ULY is expecting String
  const formattedVehicle = { ...towExchange.vehicle, year: towExchange.vehicle?.year?.toString() }

  DEFAULT_CUSTOM_VIN_VEHICLE.vin = towExchange.customVin ?? ''
  const customFormattedVehicle = { ...DEFAULT_CUSTOM_VIN_VEHICLE, year: DEFAULT_CUSTOM_VIN_VEHICLE.year.toString() }

  let request = standard
  request = towExchange.customVin
    ? addVehicle(request, customFormattedVehicle)
    : (request = addVehicle(request, formattedVehicle))

  let pickupLocation, dropoffLocation, exchangeLocation

  const customerLocation = towExchange.serviceLocations.callerLocation
  const serviceLocation = towExchange.serviceLocations.vehicleDestinationLocation
  const branchLocation = towExchange.serviceLocations.exchangeLocation

  const isInGarage = towExchange.breakdownOption === BreakdownOptions.PARKINGGARAGE
  const breakdownLocation =
    towExchange.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE ||
    towExchange.breakdownOption === BreakdownOptions.PARKINGGARAGE
      ? towExchange.breakdownOption + ': '
      : ''

  if (branchLocation) pickupLocation = addServiceLocation(branchLocation, isInGarage) as UlyPickupLocation
  if (serviceLocation) dropoffLocation = addServiceLocation(serviceLocation) as UlyDropoffLocation
  if (customerLocation) exchangeLocation = addServiceLocation(customerLocation) as UlyExchangeLocation

  const exchangeNote = 'This request is part of a tow exchange. '

  // Add Payment Information
  const paymentInfo = addPaymentInfo(request)

  // Add Service
  const serviceRequestPayload = {
    code: serviceCode as unknown as string,
    name: ulyServiceCodeLabels[serviceCode],
    notes: breakdownLocation + exchangeNote + towExchange.notes + ' ' + REQUIRED_PHOTOS_COMMENT,
    scheduledAt: towExchange.scheduledAt,
    pickupLocation,
    dropoffLocation,
    exchangeLocation,
    tags: [{ tag: 'unattended.tow', value: towExchange.unattended ? 'true' : 'false' }],
    paymentInfo,
    disablementReason,
  }

  request = addService(request, serviceRequestPayload)

  return request
}

export const createTowService = (
  standard: CaseServiceCall,
  tow: Tow & { scheduledAt?: string },
  serviceCode: UlyServiceCodes,
  disablementReason: UlyDisablementReason,
): CaseServiceCall => {
  const { vehicle, serviceLocations, notes, breakdownOption, scheduledAt, customVin } = tow

  // Garage & Breakdown Locations
  const isInGarage = breakdownOption === BreakdownOptions.PARKINGGARAGE
  const breakdownLocation =
    breakdownOption === BreakdownOptions.ROADWAYINTERSTATE || breakdownOption === BreakdownOptions.PARKINGGARAGE
      ? breakdownOption + ': '
      : ''
  // EHI Ticket vehicle year is INT -- ULY is expecting String
  const formattedVehicle = { ...vehicle, year: vehicle?.year?.toString() }

  DEFAULT_CUSTOM_VIN_VEHICLE.vin = tow.customVin ?? ''
  const customFormattedVehicle = { ...DEFAULT_CUSTOM_VIN_VEHICLE, year: DEFAULT_CUSTOM_VIN_VEHICLE.year.toString() }

  let request = standard
  request = customVin ? addVehicle(request, customFormattedVehicle) : (request = addVehicle(request, formattedVehicle))

  // Add Service Locations
  let pickupLocation, dropoffLocation, customerPickupLocation, customerDropoffLocation, exchangeLocation
  const vehicleLocation = serviceLocations.vehicleLocation
  const towLocation = serviceLocations.vehicleDestinationLocation
  if (vehicleLocation) pickupLocation = addServiceLocation(vehicleLocation) as UlyPickupLocation
  if (towLocation) dropoffLocation = addServiceLocation(towLocation, isInGarage) as UlyDropoffLocation
  if (tow.rideAlong && serviceLocations.exchangeLocation) {
    exchangeLocation = addServiceLocation(serviceLocations.exchangeLocation) as UlyExchangeLocation
  }

  // Only swap the exchange location when it's Tow Disabled. We only pass 2 locations for this service anyways
  if (serviceLocations.exchangeLocation && serviceLocations.callerLocation && Object.keys(serviceLocations).length === 2) {
    pickupLocation = addServiceLocation(serviceLocations.exchangeLocation) as UlyPickupLocation
    dropoffLocation = addServiceLocation(serviceLocations.callerLocation) as UlyDropoffLocation
  }

  // Check for custom pickup location for customer
  customerPickupLocation = (
    tow.serviceLocations.callerLocation ? addCallerPickupLocation(tow, pickupLocation) : pickupLocation
  ) as UlyPickupLocation

  // Check for custom dropoff location for customer
  customerDropoffLocation = (
    tow.rideAlong === false ? addCallerDropoffLocation(tow, dropoffLocation) : dropoffLocation
  ) as UlyDropoffLocation

  // Add Payment Information
  const paymentInfo = addPaymentInfo(request)

  // Add Service
  const serviceRequestPayload = {
    code: serviceCode as unknown as string,
    name: ulyServiceCodeLabels[serviceCode],
    notes: breakdownLocation + notes + ' ' + REQUIRED_PHOTOS_COMMENT,
    tags: [{ tag: 'unattended.tow', value: tow.unattended ? 'true' : 'false' }],
    scheduledAt,
    pickupLocation,
    dropoffLocation,
    paymentInfo,
    disablementReason,
  } as Partial<UlyServices>

  if (tow.rideAlong && !isNil(exchangeLocation)) {
    serviceRequestPayload.exchangeLocation = exchangeLocation
  }

  request = addService(request, serviceRequestPayload)

  // Add STM ride
  if (tow.rideAlong === false) {
    const stmRequestPayload = {
      notes,
      paymentInfo,
      // 0 - One way trip || 1 - Round Trip
      roundTrip: 0,
      passengerCount: tow.passengerCount?.value || 1,
      luggageCount: tow.luggageCount?.value || 0,
      pickupLocation: customerPickupLocation,
      dropoffLocation: customerDropoffLocation,
    }

    request = addRide(request, stmRequestPayload)
  }

  return request
}

// Modify Case functions
export const addService = (request: CaseServiceCall, service: Partial<UlyServices>) =>
  ({ ...request, services: [...request.services, service] }) as CaseServiceCall

export const addRide = (request: CaseServiceCall, ride: UlyRide) => ({ ...request, ride }) as CaseServiceCall

export const addPaymentInfo = (request: CaseServiceCall): UlyPaymentInfo[] => {
  const customerRA = request.customer.id
  const timestamp = new Date().toISOString()
  const authToken = `${customerRA}-${timestamp}`
  return [{ payer: `${ULY_PARTNER_CODES.Partner}`, authorizedToken: authToken }]
}

export const addCustomerContact = (request: CaseServiceCall, contact: ULYCustomerContact): CaseServiceCall => ({
  ...request,
  customer: {
    ...request.customer,
    name: contact.name,
    phone: contact.phone,
    countryPhoneCode: contact.countryPhoneCode || '',
  },
  contactInfo: {
    ...request.contactInfo,
    callbackName: contact.name,
    callbackNumber: contact.phone,
    countryPhoneCode: contact.countryPhoneCode,
    isMobilePhone: contact.isMobilePhone,
    textOptIn: contact.textOptIn,
  },
})

export const addVehicle = (request: CaseServiceCall, vehicle: PartialVehicleWithStringYear): CaseServiceCall => ({
  ...request,
  vehicle: {
    ...request.vehicle,
    owned: 0,
    transmission: 0,
    fuelType: 0,
    id: vehicle.vin,
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    color: vehicle.color,
    vin: vehicle.vin,
    licensePlate: `${vehicle.licensePlate} ${vehicle.licensePlateState}`,
    state: vehicle.licensePlateState,
  },
})

export const addServiceLocation = (location: LocationPayload, isInGarage?: boolean) => ({
  ...locationToULYLocation(location, isInGarage),
})

export const addCallerPickupLocation = (tow: Tow | TowExchange, secondaryLocation: UlyPickupLocation) => {
  if (tow.serviceLocations.callerLocation) {
    const callerLocation = tow.serviceLocations.callerLocation as unknown
    const loc = addServiceLocation(callerLocation as LocationPayload)
    return loc
  }
  return secondaryLocation
}

export const addCallerDropoffLocation = (tow: Tow | TowExchange, secondaryLocation: UlyDropoffLocation) => {
  if (tow.rideAlong === false && tow.thirdPartyLocation) {
    const dropoffLocation = tow.thirdPartyLocation as LocationPayload
    return {
      ...locationToULYLocation(dropoffLocation, tow.breakdownOption === BreakdownOptions.PARKINGGARAGE),
    }
  }

  if (tow.rideAlong === false && tow.serviceLocations.vehicleDestinationLocation) {
    return addServiceLocation(tow.serviceLocations.vehicleDestinationLocation)
  }

  return secondaryLocation
}

export const locationToULYLocation = (location: LocationPayload, isInGarage: boolean | undefined) => {
  let locationTypeCode

  const isVehicleInGarage = isInGarage || false

  // Network Manager
  if (isParticipant(location)) {
    const { addressLine1, city, administrativeArea, postalCode, country } = location.entity.address!
    const { latitude, longitude } = location.location!

    return {
      ...formatUlyAddress(addressLine1!, city!, administrativeArea!, postalCode!, country!),
      longitude,
      latitude,
      timezone: tzLookup(latitude, longitude),
      locationType: UlyLocationTypeCodes['Residence/work (parking lot)'],
      // facilityCode: location.assigneeEntityId.toString(),
      locationName: location.entity.name,
    }
  }

  // Breakdown
  if (isLocation(location)) {
    const breakdownLocation = location as LocationPayload
    const { street = '', city, state, streetNumber = '', postalCode, country } = breakdownLocation.address

    if (streetNumber) {
      locationTypeCode = isVehicleInGarage
        ? UlyLocationTypeCodes['General Parking Garage']
        : UlyLocationTypeCodes['Roadside (street)']
    } else {
      locationTypeCode = UlyLocationTypeCodes['Roadside (highway)']
    }

    return {
      ...formatUlyAddress(`${streetNumber || ''} ${street || ''}`, city, state, postalCode, country),
      latitude: breakdownLocation.latitude!,
      longitude: breakdownLocation.longitude!,
      timezone: tzLookup(breakdownLocation.latitude, breakdownLocation.longitude),
      locationType: locationTypeCode,
    }
  }

  // Rental Branch
  if (isBranch(location)) {
    const branchLocation = location as Branch
    const { street_addresses, city, postal, country_code, country_subdivision_code: state } = branchLocation.address

    return {
      ...formatUlyAddress(street_addresses[0], city, state, postal, country_code),
      latitude: parseFloat(branchLocation.gps.latitude.toString()),
      longitude: parseFloat(branchLocation.gps.longitude.toString()),
      timezone: tzLookup(parseFloat(branchLocation.gps.latitude.toString()), parseFloat(branchLocation.gps.longitude.toString())),
      locationType: UlyLocationTypeCodes['Residence/work (parking lot)'],
      locationName: `${branchLocation.brand} - ${branchLocation.additional_data.group_branch_number}`,
    }
  }
  return undefined
}

const formatUlyAddress = (
  address: string,
  city: string,
  state: string,
  zip: string,
  country: string,
): Partial<UlyServiceLocation> => {
  let street: string = address
  const [streetNumber, ...rest] = address && address.split(' ')
  if (isNumeric(streetNumber)) {
    street = `${streetNumber} ${rest.join(' ')}`
  } else {
    street = `${address} ${city} ${state}, ${country} ${zip}`
  }

  const fullAddress = `${address} ${city}, ${state} ${zip}, ${country}`
  return {
    address: fullAddress,
    street,
    city,
    state,
    zip,
    country,
  }
}

export function splitReverseTwoWayTow(service: Tow | TowExchange) {
  const { callerLocation, vehicleLocation, vehicleDestinationLocation } = service.serviceLocations

  const towServiceLocations = {
    vehicleLocation: isNil(vehicleLocation) ? callerLocation : vehicleLocation,
    vehicleDestinationLocation,
  }
  const towRequest: Tow = {
    ...service,
    serviceLocations: towServiceLocations,
  }

  const { exchangeLocation } = service.serviceLocations
  const exchangeServiceLocations = {
    exchangeLocation,
    callerLocation,
  }
  const exchangeNote = 'This request is part of a tow exchange. '
  const exchangeRequest: TowExchange = {
    ...(service as TowExchange),
    serviceLocations: exchangeServiceLocations,
    notes: exchangeNote + service.notes,
  }

  return [towRequest, exchangeRequest]
}

export function generateDisablementNotes(eventType: EventTypes, service: SoftService | Tow | TowExchange, spareTire?: boolean) {
  switch (eventType) {
    case EventTypes.EXCHANGE:
    case EventTypes.TOW: {
      const towService = service as TowExchange
      const isDeliveryOnly = towService.disabledVehiclePickup === false
      const towReason = isDeliveryOnly ? VehicleConditions.OTHER : (towService.vehicleConditionSelect.value as VehicleConditions)

      const isRideAlong = towService.rideAlong === true
      const isThirdParty = towService.rideAlong === false
      const isNewVehicleDelivery = towService.rideAlong === undefined
      const isUnattended = towService.unattended || false
      const isVehicleInGarage = towService.breakdownOption === BreakdownOptions.PARKINGGARAGE || false
      const isCustomerSuppliedTransport = towService.customerSupplied || false

      const vehicleCondition = isDeliveryOnly ? 'Vehicle Condition? Other' : towService.vehicleCondition
      const rideAlongNotes = `Ride Along? ${isRideAlong ? 'yes' : 'no'}`
      const thirdPartyNotes = `Third Party ${isThirdParty ? 'yes' : 'no'}`
      const newVehicleDeliveryNotes = `New Vehicle Delivery? ${isNewVehicleDelivery && !isCustomerSuppliedTransport ? 'yes' : 'no'}`
      const isDeliveryOnlyNotes = `Is Delivery Only? ${isDeliveryOnly ? 'yes' : 'no'}`
      const unAttendedNotes = `Is Vehicle Unattended? ${isUnattended ? 'yes' : 'no'}`
      const vehicleGarageNotes = `Is vehicle parked in garage? ${isVehicleInGarage ? 'yes' : 'no'}`
      const customerSuppliedNotes = `Is Customer Supplying own transportation? ${isCustomerSuppliedTransport ? 'yes' : 'no'}`

      let keyNotes
      let otherKeyNotes
      if (isUnattended && typeof towService.keysLocation === 'object') {
        const keyLocation = towService.keysLocation.label
        const otherLocation = towService.keysLocationOther

        keyNotes = `Key Location: ${keyLocation}`
        if (otherLocation) {
          otherKeyNotes = `Key Location ( Other ): ${otherLocation}`
        }
      }

      const towNotes = [
        vehicleCondition,
        rideAlongNotes,
        thirdPartyNotes,
        customerSuppliedNotes,
        newVehicleDeliveryNotes,
        isDeliveryOnlyNotes,
        unAttendedNotes,
        vehicleGarageNotes,
        keyNotes,
        otherKeyNotes,
      ].join('\n')

      switch (towReason) {
        case VehicleConditions.LOST_KEY:
        case VehicleConditions.KEYS_LOCKED: {
          const keyService = service as Lockout & Tow
          const { lostKeys, personalBelongings, trunkAccess } = keyService

          const lostKeyNotes = `Lost Keys? ${lostKeys || 'N/A'}`
          const belongingNotes = `Personal Belongings in Vehicle? ${personalBelongings || 'N/A'}`
          const accessNotes = `Trunk Access? ${trunkAccess || 'N/A'}`
          const additionalNotes = [lostKeyNotes, belongingNotes, accessNotes].join('\n')

          return `${towNotes}, ${additionalNotes}`
        }
        case VehicleConditions.ACCIDENT:
        case VehicleConditions.STUCK:
        case VehicleConditions.NO_START:
        case VehicleConditions.OUT_OF_CHARGE:
        case VehicleConditions.FLAT_NO_SPARE:
        case VehicleConditions.FIRE:
        case VehicleConditions.MIS_FUELED:
        case VehicleConditions.LEAKING_FLUIDS:
        case VehicleConditions.BRAKE_FAILURE:
        case VehicleConditions.SUSPENSION_FAILURE:
        case VehicleConditions.TRANSMISSION_FAILURE:
        case VehicleConditions.OVERHEATED:
        case VehicleConditions.RUNS_POORLY:
        case VehicleConditions.MAINTENANCE_LIGHT:
        case VehicleConditions.BROKEN_GLASS:
        case VehicleConditions.OTHER:
        default: {
          return towNotes
        }
      }
    }
    case EventTypes.TIRES_CHANGE_SPARE: {
      const tireService = service as unknown as Tires
      const vehicleSpare = `Has spare tire? ${spareTire ? 'Yes' : 'No'}`
      const TireCondition = `Tire Condition: ${tireService.condition}`
      const tireNotes = [vehicleSpare, TireCondition].join('\n')
      return tireNotes
    }
    case EventTypes.JUMPSTART: {
      const jumpstartService = service as Jumpstart
      const vehicleStatus = `Status: ${jumpstartService.vehicleStatus}`
      const vehicleNoise = `What sound does the car make? ${jumpstartService.noise || 'N/A'}`
      const vehicleFuelLevel = `Is vehicle out of fuel? ${jumpstartService.outOfFuel || 'N/A'}`
      const jumpstartNotes = [vehicleStatus, vehicleNoise, vehicleFuelLevel].join('\n')
      return jumpstartNotes
    }
    case EventTypes.LOCKOUT: {
      const lockoutService = service as Lockout
      const { keysLocation, lostKeys, personalBelongings, trunkAccess } = lockoutService
      const lostKeyNotes = `Lost Keys? ${lostKeys}`
      const belongingNotes = `Personal Belongings in Vehicle? ${personalBelongings || 'N/A'}`
      const accessNotes = `Trunk Access? ${trunkAccess || 'N/A'}`
      const keyLocation = `Key Location: ${keysLocation || 'N/A'}`
      const lockoutNotes = [lostKeyNotes, belongingNotes, accessNotes, keyLocation].join('\n')
      return lockoutNotes
    }
    case EventTypes.WINCH: {
      const winchService = service as Winch
      const okayToDriveNotes = `Okay to Drive? ${winchService.okayToDrive}`
      const distanceNotes = `More than 50ft from Pavement?: ${winchService.moreThan50ft}`
      const winchNotes = [okayToDriveNotes, distanceNotes].join('\n')
      return winchNotes
    }
    case EventTypes.FUEL: {
      const fuelNotes = 'Vehicle out of fuel. Unleaded fuel.'
      return fuelNotes
    }
    default: {
      const serviceNotes = `Service Type Created: ${eventType}`
      const notes = `No notes available`
      const defaultNotes = [serviceNotes, notes].join('\n')
      return defaultNotes
    }
  }
}

export function generateDisablementCode(eventType: EventTypes, service: SoftService | Tow | TowExchange, spareTire?: boolean) {
  switch (eventType) {
    case EventTypes.EXCHANGE:
    case EventTypes.TOW: {
      const towService = service as TowExchange
      const isDeliveryOnly = towService.disabledVehiclePickup === false
      const towReason = isDeliveryOnly ? VehicleConditions.OTHER : (towService.vehicleConditionSelect.value as VehicleConditions)

      switch (towReason) {
        case VehicleConditions.ACCIDENT: {
          return 'accident'
        }
        case VehicleConditions.STUCK: {
          return 'flood'
        }
        case VehicleConditions.NO_START: {
          return 'not.start'
        }
        case VehicleConditions.OUT_OF_CHARGE: {
          return 'out.of.charge'
        }
        case VehicleConditions.FLAT_TIRES: {
          return 'multiple.ft'
        }
        case VehicleConditions.FLAT_NO_SPARE: {
          return 'ft.no.spare'
        }
        case VehicleConditions.FIRE: {
          return 'vehicle.fire'
        }
        case VehicleConditions.MIS_FUELED: {
          return 'fuel'
        }
        case VehicleConditions.LEAKING_FLUIDS: {
          return 'fluid.leak'
        }
        case VehicleConditions.BRAKE_FAILURE: {
          return 'brakes'
        }
        case VehicleConditions.SUSPENSION_FAILURE: {
          return 'suspension'
        }
        case VehicleConditions.TRANSMISSION_FAILURE: {
          return 'transmission'
        }
        case VehicleConditions.LOST_KEY: {
          return 'key.missing'
        }
        case VehicleConditions.KEYS_LOCKED: {
          return 'keys.lock.passenger'
        }
        case VehicleConditions.OVERHEATED: {
          return 'engine.overheat.warn'
        }
        case VehicleConditions.RUNS_POORLY: {
          return 'engine.performance'
        }
        case VehicleConditions.MAINTENANCE_LIGHT: {
          return 'warning.lights'
        }
        case VehicleConditions.BROKEN_GLASS: {
          return 'mirror.windshield'
        }
        case VehicleConditions.OTHER:
        default: {
          return 'others'
        }
      }
    }
    case EventTypes.TIRES_CHANGE_SPARE: {
      return spareTire ? 'ft.standard.spare' : 'ft.no.spare'
    }
    case EventTypes.JUMPSTART: {
      return 'not.start'
    }
    case EventTypes.LOCKOUT: {
      const { keysLocation } = service as Lockout
      if (!keysLocation) return 'keys.lock.passenger'
      return keysLocation === 'trunk' ? 'keys.lock.trunk' : 'keys.lock.passenger'
    }
    case EventTypes.WINCH: {
      return 'mech.disablement'
    }
    case EventTypes.FUEL: {
      return 'out.of.fuel'
    }
    default: {
      return 'others'
    }
  }
}

export const SoftServiceCodes: { [key: string]: any } = {
  [EventTypes.TIRES_CHANGE_SPARE]: 2005,
  [EventTypes.JUMPSTART]: 2003,
  [EventTypes.LOCKOUT]: 2002,
  [EventTypes.FUEL]: 2004,
  [EventTypes.WINCH]: 2007,
  [EventTypes.TOW]: 2001,
  [EventTypes.EXCHANGE]: 2068,
}
